<template>
  <div class="inventory-container" :class="{ 'inventory-open': showInventory }">
    <button class="inventory-button" @click="toggleInventory">
      <span class="inventory-label">
        <span class="vertical-text">{{ $t('your_inventory') }}</span>
        <span class="inventory-counter">{{ collectedHotspotsCount }}/{{ totalHotspots }}</span>
      </span>
    </button>
    <InventoryContent />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import whitelabel from '@/assets/whitelabel.json';
import Cookies from 'js-cookie';
import emitter from '@/eventBus.js';
import InventoryContent from './InventoryContent.vue';

// State
const showInventory = ref(false);
const collectionUpdateTrigger = ref(0); // Reactive reference to force updates

// Computed properties
const totalHotspots = computed(() => {
  console.log('Total hotspots:', whitelabel.hotspots.length);
  return whitelabel.hotspots.length;
});

const collectedHotspotsCount = computed(() => {
  console.log('Computing collectedHotspotsCount, trigger:', collectionUpdateTrigger.value);

  let collectedCount = 0;

  whitelabel.hotspots.forEach(hotspot => {
    const cookieKey = `collected-hotspot-${hotspot.id}`;
    if (Cookies.get(cookieKey)) {
      collectedCount++;
    }
  });

  console.log('Collected hotspots:', collectedCount);
  return collectedCount;
});

// Methods
const toggleInventory = () => {
  showInventory.value = !showInventory.value;
};

const onHotspotCollected = () => {
  collectionUpdateTrigger.value++; // Increment to trigger the update
};

// Lifecycle hooks
onMounted(() => {
  emitter.on('hotspot-collected', onHotspotCollected);
  emitter.on('scapbook-opened', toggleInventory);
});

onUnmounted(() => {
  emitter.off('hotspot-collected', onHotspotCollected);
  emitter.off('scapbook-opened', toggleInventory); // Changed to off instead of on
});
</script>

<style scoped>
.inventory-container {
  position: fixed;
  top: 50%;
  height: 80vh;
  right: -645px; /* Show only 60px of the inventory container initially */
  transform: translateY(-50%);
  display: flex;
  transition: transform 0.3s ease, right 0.3s ease;
}

.inventory-button {
  background-color: white;
  border: 1px solid #ccc;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  cursor: pointer;
  z-index: 2; /* Ensure the button is above the inventory content */
  padding: 0; /* Remove padding to allow full control over positioning */
}

.inventory-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: bold;
  margin: auto 0; /* Center vertically */
}

.inventory-counter {
  position: absolute;
  bottom: 10px; /* Position the counter at the bottom */
  font-size: 0.8rem;
}

.inventory-content {
  background-color: white;
  width: 600px;
  border: 0px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* When the inventory is open, move the entire container into view */
.inventory-open {
  right: 0;
}
</style>
